.doctors-calendar{
    .rbc-toolbar>.rbc-btn-group:first-child{
        /*display: none;*/
    }

    .rbc-toolbar-label{
        text-align: left;
    /*padding-left: 0;*/
    color: #001737;
    text-transform: capitalize;
    font-family: "nunito-regular", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    }
}