.user-bio-form{
    .form-control.form-control-solid {
        background-color: #f3f6f9;
        border-color: #f3f6f9;
        color: #464e5f;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
    }
    #filetrigger{
        margin-top: -20px;
        position: absolute;
        left: 10px;
        height: 150px;
        top: 20px;
        width: 150px;
        opacity: 0;
        cursor: pointer;
    }

    #kt_profile_avatar img{
        border-radius: 10px;
        border: 3px solid #fff;
        -webkit-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0,0,0,.075);
        box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0,0,0,.075);
        cursor: pointer;
        height: 150px;
    }

    .image-input{
        transition: opacity .6s;
    }

    .image-input.hovered{
        opacity: 0.75;
    }
}