.setting {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.setting__name {
  width: 300px;
  color: #222;
}

.setting__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 450px;
}
