.steps-indicator{
    &.steps-container {
        width: 100%;
        color: white;
        background: #2979FF;
        font-family: 'Roboto', sans-serif;
        margin-top: 0;
      }
      
      .progressbar {
        counter-reset: step;
      }
      
      .progressbar li {
        position: relative;
        list-style: none;
        float: left;
        width: 33.33%;
        text-align: center;
      }
      
      /* Circles */
      .progressbar li:before {
        content: counter(step);
        counter-increment: step;
        width: 30px;
        height: 30px;
        border: 0px;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: #FF9100;
         
        /* Center # in circle */
        line-height: 31px;
      }
      
      .progressbar li:after {
        content: "";
        position: absolute;
        width: calc(100% - 40px);
        height: 1px;
        background: orange ;
        top: 20px; /*half of height Parent (li) */
        left: calc(-50% + 20px);
        z-index: 0;
      }
      
      .progressbar li:first-child:after {
        content: none;
      }
      
      .progressbar li.active:before {
        background: #00E676;
        content: "✔";  
      }
      
      .progressbar li.active + li:after {
        background: #00E676;
      }
}
