.file-preview-container {
  #file-name,
  #header-bar {
    display: none;
  }

  .icoRhe {
    background-image: none !important;
  }

  #pdf-controls {
    padding-right: 30px;
  }

  &.lightbox-style {
    #pdf-controls,
    #react-doc-viewer {
      background: transparent !important;
      box-shadow: none !important;
    }
  }
}
