.my-table{
    .kt-quick-search__input{
        background: transparent !important;
    }

    .delete-row-trigger{
        padding: 2px;
        border-radius: 50px;
        height: 22px;
        width: 22px;
        display: inline-block;
        transition: all ease-out 0.5s;
        cursor: pointer;
        
        i{
            transition: all ease-out 0.5s;
            color: #999;
        }
    }

    .delete-row-trigger:hover{
        background-color: rgba(40,40,40,0.075);
        i{
            top: 2px;
            position: relative;
        }

        *{
            color: #dc3545 !important;
        }
        
    }
}